export const privacyPolicyUrl = 'https://drive.google.com/file/d/1DOh7A79ZJqDwZ6dm2lst-3OpyS9jNbpp/view?usp=sharing';
export const cookiesPolicyUrl = 'https://drive.google.com/file/d/1B0tTGxfunR4Sc_awPw05JDfp2YSsYKIp/view?usp=sharing';
export const herokuUrl = 'https://bluehouse-site.herokuapp.com';

export const portalId = '8599673';
export const joinUsFormId = 'd0b43183-73cc-40ca-8f0d-192e385a274a';
export const buildTeamFormId = 'b9d56184-66e3-4e50-917c-e1e53fea869c';
export const subscribeFormId = '8c29aa52-3748-402e-9062-0eca79dd34f4';
export const newsletterFormId = '2daf95b7-5335-481b-b63d-ad1240debe4e';
export const bookFormId = '08587d77-dc98-4198-9185-9e17d50a95ea';
