import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import st from './nav.module.scss';
export const URLS = {
  CLIENTS: '/clients/',
  COMMUNITY: '/community/',
  ABOUT: '/about/',
  BLOG: '/blog/',
  JOIN: '/join-us/',
  BUILD: '/contact-us/',
};
export const URLS_TRIMMERED = {
  CLIENTS: '/clients',
  COMMUNITY: '/community',
  ABOUT: '/about',
  BLOG: '/blog',
  JOIN: '/join-us',
  BUILD: '/contact-us',
};
const transparentPaths = [
  URLS.CLIENTS,
  URLS_TRIMMERED.CLIENTS,
  URLS.COMMUNITY,
  URLS_TRIMMERED.COMMUNITY,
  URLS.ABOUT,
  URLS_TRIMMERED.ABOUT,
];
const reversedPaths = [URLS.JOIN, URLS_TRIMMERED.JOIN, URLS.BUILD, URLS_TRIMMERED.BUILD];

const Nav = ({isTransparent, isReversed}) => {
  return (
    <div className={`${st.nav__wrapper} ${isTransparent ? st.transparent : ''} ${isReversed ? st.reversed : ''}`}>
      <nav className={st.navbar}>
        <Link to='/'>
          <img
            className={st.image}
            src={isTransparent || isReversed ? '/logo_bluehouse_white.png' : '/logo_bluehouse.png'}
            alt='Blue House logo'
          />
        </Link>
        <Links />
      </nav>
    </div>
  );
};

const MobileNav = () => {
  const [openNav, setOpenNav] = useState(false);
  const handleClick = () => {
    setOpenNav(!openNav);
  };

  const html = typeof document !== `undefined` ? document.querySelector('html') : null;
  useEffect(() => {
    openNav ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible');
  }, [openNav]);

  return (
    <div className={`${st.mobile__nav__wrapper}`}>
      <nav className={st.mobile__navbar}>
        <Link to='/'>
          <img className={st.image} src={'/logo_bluehouse_white.png'} alt='Blue House logo' />
        </Link>
        <div className={st.icon__wrapper}>
          <FontAwesomeIcon
            className={`${st.bars} ${openNav ? st.nav__open : ''}`}
            onClick={handleClick}
            icon={faBars}
            size={'2x'}
            color={'white'}
          />
        </div>
        <Links openNav={openNav} />
      </nav>
    </div>
  );
};

export const Links = ({openNav = false}) => (
  <ul className={`${st.navbar__nav} ${openNav ? st.nav__open : ''}`}>
    <Link className={st.navbar__link} to={URLS.CLIENTS} activeClassName={st.active}>
      <li className={st.navbar__item}>For Clients</li>
    </Link>

    <Link className={st.navbar__link} to={URLS.COMMUNITY} activeClassName={st.active}>
      <li className={st.navbar__item}>Community</li>
    </Link>

    <Link className={st.navbar__link} to={URLS.ABOUT} activeClassName={st.active}>
      <li className={st.navbar__item}>About Us</li>
    </Link>
    <Link className={st.navbar__link} to={URLS.BLOG} activeClassName={st.active}>
      <li className={st.navbar__item}>Blog</li>
    </Link>

    <Link className={st.navbar__link} to={URLS.JOIN} activeClassName={st.active}>
      <li className={st.navbar__item}>Join Us</li>
    </Link>
    <Link className={`${st.navbar__link} ${st.navbar__link__blue}`} to={URLS.BUILD} activeClassName={st.active}>
      <li className={st.navbar__item}>Contact Us</li>
    </Link>
    <Link
      className={`${st.navbar__link} ${st.navbar__link__blue} ${st.navbar__link__button}`}
      to='https://www.notion.so/Job-Board-5a70fd6299dd4e7dbc65797486be23b6'
      target='_blank'
      activeClassName={st.active}
    >
      <li className={st.navbar__item}>Career</li>
    </Link>
  </ul>
);

export const Navigation = ({pathName}) => {
  let isTransparent = transparentPaths.includes(pathName);
  let isReversed = reversedPaths.includes(pathName);

  useEffect(() => {
    isTransparent = transparentPaths.includes(pathName);
    isReversed = reversedPaths.includes(pathName);
    console.log(pathName);
  }, [pathName]);
  return (
    <>
      <Nav pathName={pathName} isTransparent={isTransparent} isReversed={isReversed} />
      <MobileNav />
    </>
  );
};
