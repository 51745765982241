import React from 'react';
import st from './button.module.scss';
import {Link} from 'gatsby';

interface ButtonProps {
  children: string | React.ReactNode;
  to?: string;
  className?: 'primary' | 'secondary';
  onClick?: (e) => void;
  stClassName?: string;
  disabled?: boolean;
}

export const Button = ({
  to,
  onClick,
  className = 'primary',
  children,
  stClassName = '',
  disabled = false,
}: ButtonProps) => {
  return to ? (
    <Link to={to}>
      <button className={`${st.button} ${st[className]} ${stClassName}`}>{children}</button>
    </Link>
  ) : (
    <button disabled={disabled} className={`${st.button} ${st[className]} ${stClassName}`} onClick={onClick}>
      {children}
    </button>
  );
};
