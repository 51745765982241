import React from 'react';
import {Footer} from '../footer/footer';
import {Navigation} from '../nav/nav';
import CookieConsent from 'react-cookie-consent';
import {cookiesPolicyUrl} from '../../api';
import {Helmet} from 'react-helmet';
import st from './layout.module.scss';
export const Layout = props => {
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/8599673.js'></script>
        {/* Tracking */}
        {(function () {
          if (typeof window !== 'undefined') {
            window.ldfdr = window.ldfdr || {};
            (function (d, s, ss, fs) {
              fs = d.getElementsByTagName(s)[0];
              function ce(src) {
                var cs = d.createElement(s);
                cs.src = src;
                setTimeout(function () {
                  fs.parentNode.insertBefore(cs, fs);
                }, 1);
              }
              ce(ss);
            })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_lYNOR8xn3Bv4WQJZ.js');
          }
        })()}
      </Helmet>
      <Navigation pathName={pathName} />
      <CookieConsent
        location='bottom'
        buttonText='I agree'
        declineButtonText='Decline'
        cookieName='gatsby-gdpr-google-analytics'
        containerClasses={st.cookies}
      >
        We use cookies to ensure you have the best browsing experience on our website. For more information see our{' '}
        <a href={cookiesPolicyUrl} target='_blank' rel='noreferrer'>
          Cookies Policy
        </a>
        .
      </CookieConsent>
      {props.children}
      <Footer />
    </>
  );
};
