import React from 'react';
import {Link, useStaticQuery, graphql} from 'gatsby';
import st from './footer.module.scss';
import {URLS} from '../nav/nav';

export const Footer = () => {
  const {
    restApiHomePage: {
      footer: {Email, Company_name, NIP, KRS, Phone, REGON},
    },
  } = query();
  return (
    <div className={st.footer}>
      <div className={st.footer__section}>
        <div className={st.footer__wrapper}>
          <div className={st.footer__logo}>
            <img className={st.image} src={'/logo_bluehouse_white.png'} alt='Blue House logo' />
          </div>
          <div className={st.footer__information}>
            <div className={st.footer__information__top}>
              <div>
                <a className={st.text__blue} href={`tel:${Phone}`}>
                  {Phone}
                </a>
              </div>
              <div>
                <a className={st.text__blue} href={`mailto:${Email}`}>
                  {Email}
                </a>
              </div>
            </div>
            <div className={st.footer__information__bottom}>
              <p>{Company_name}</p>
              <p>NIP: {NIP}</p>
              <p>REGON: {REGON}</p>
              <p>KRS: {KRS}</p>
            </div>
          </div>
          <div className={st.footer__menu}>
            <div className={st.footer__menu__wrapper}>
              <Link to={URLS.CLIENTS} activeClassName={st.active}>
                For Clients
              </Link>
              <Link to={URLS.COMMUNITY} activeClassName={st.active}>
                Community
              </Link>
              <Link to={URLS.ABOUT} activeClassName={st.active}>
                About Us
              </Link>
              <Link to={URLS.BLOG} activeClassName={st.active}>
                Blog
              </Link>
              <Link to={URLS.JOIN} activeClassName={st.active}>
                Join Us
              </Link>
              <Link to={URLS.BUILD} activeClassName={st.active}>
                Contact Us
              </Link>
              <Link
                className={st.link__button}
                to='https://www.notion.so/Job-Board-5a70fd6299dd4e7dbc65797486be23b6'
                target='_blank'
                activeClassName={st.active}
              >
                Career
              </Link>
            </div>
          </div>
        </div>
        <div className={`${st.footer__wrapper} ${st.footer__wrapper__bottom}`}>
          <div className={st.footer__brands}>
            <a href='https://twitter.com/BlueHousePL' target='_blank' rel='noreferrer' aria-label='Twitter'>
              <img src={'/twitter.svg'} alt={'twitter'} />
            </a>
            <a
              href='https://www.linkedin.com/company/blue-house-technologies/'
              target='_blank'
              rel='noreferrer'
              aria-label='LinkedIn'
            >
              <img src={'/linkedin.svg'} alt={'linkedin'} />
            </a>
            <a
              href='https://www.instagram.com/bluehousecommunity/'
              target='_blank'
              rel='noreferrer'
              aria-label='Instagram'
            >
              <img src={'/instagram.svg'} alt={'instagram'} />
            </a>
          </div>
          <div className={st.footer__copyright}>Copyright BlueHouse © - {new Date().getFullYear()}</div>
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(
    graphql`
      query {
        restApiHomePage {
          id
          footer {
            Email
            Company_name
            NIP
            KRS
            Phone
            REGON
          }
        }
      }
    `,
  );
